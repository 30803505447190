<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.03553 9.41053C8.56467 9.88139 7.68051 10.7656 6.91421 11.5319C6.13316 12.3129 4.86703 12.3131 4.08598 11.532C3.33421 10.7803 2.46437 9.91044 1.96447 9.41053C0.0118447 7.45791 0.0118447 4.29209 1.96447 2.33947C3.91709 0.386845 7.08291 0.386845 9.03553 2.33947C10.9882 4.29209 10.9882 7.45791 9.03553 9.41053Z"
      stroke="white"
      stroke-opacity="0.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.375 5.875C7.375 6.91053 6.53553 7.75 5.5 7.75C4.46447 7.75 3.625 6.91053 3.625 5.875C3.625 4.83947 4.46447 4 5.5 4C6.53553 4 7.375 4.83947 7.375 5.875Z"
      stroke="white"
      stroke-opacity="0.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
